@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import "app/styles/colors.css";
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.3px;
}
body a {
  color: var(--white);
  text-decoration: none;
}
body ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
body ul li {
  display: block;
}
body p {
  margin: 0;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  margin: 0;
}
body svg {
  display: block;
}

.footer {
  font-size: 10px;
  line-height: 13px;
}

.button button {
  border-radius: 6px;
  font-weight: 700;
}

.title {
  font-weight: 700;
  line-height: 10px;
}

.white-text {
  color: var(--white);
}

.count {
  margin: 0 0 0 10px;
  font-weight: 700;
  line-height: 10px;
  color: var(--grey-medium);
}

.row {
  display: flex;
}

.row-align {
  display: flex;
  align-items: center;
}

.row-baseline {
  display: flex;
  align-items: baseline;
}

.row-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-between {
  display: flex;
  justify-content: space-between;
}

.row-between-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.col-between-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
  padding: 8px;
  margin: -8px;
  transition: opacity 0.2s ease;
}

.icon:hover {
  opacity: 75%;
}

.thick-text {
  font-weight: 700;
}

.grid {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  grid-gap: 15px;
  margin: 0 15px 0 0;
}

.Button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
}

