.container {
  overflow: hidden;
}
.container .sidebar {
  background-color: var(--primary-color);
  color: var(--white);
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease;
  z-index: 1000;
  overflow: hidden;
}
.container .sidebar__header {
  position: relative;
  padding: 47px 45px;
}
.container .sidebar__header__logo {
  margin: 0 32px 0 0;
  transition: all 3s ease;
}
.container .sidebar__menu__item {
  padding: 16px 45px;
}
.container .sidebar__menu__item__icon {
  margin: 0 22px 0 0;
}
.container .sidebar__menu__item__text {
  font-weight: 700;
  font-size: 14px;
}
.container .sidebar__footer {
  position: absolute;
  padding: 0 45px;
  bottom: 40px;
}
.container .sidebar__footer__text {
  white-space: nowrap;
}
.container .footer {
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
}
.container .main {
  position: absolute;
  top: 0;
  left: 300px;
  width: calc(100% - 300px);
  transition: all 0.3s ease;
  background-color: var(--grey-light-3);
  height: 100%;
}
.container .content {
  width: 100%;
}
.container .content__container {
  width: 100%;
  padding: 0 66px;
}

.sidebar-collapsed .sidebar {
  width: 90px;
  padding: 0;
  background-color: var(--primary-color-dark);
  transition: background-color 0.4s ease, width 0.3s ease;
}
.sidebar-collapsed .sidebar .sidebar__header__logo, .sidebar-collapsed .sidebar .sidebar__menu__item__text {
  display: none;
}
.sidebar-collapsed .sidebar__header {
  padding: 47px 0;
  text-align: center;
}
.sidebar-collapsed .sidebar__header-menu {
  padding: 0 20px;
}
.sidebar-collapsed .sidebar__menu {
  text-align: center;
}
.sidebar-collapsed .sidebar__menu__item {
  padding: 17px 13.5px;
  border-radius: 10px;
}
.sidebar-collapsed .sidebar__menu__item__icon {
  margin: 0;
}
.sidebar-collapsed .sidebar__footer {
  position: absolute;
  bottom: 36px;
  padding: 0 17px;
}
.sidebar-collapsed .main {
  left: 90px;
  width: calc(100% - 90px);
  transition: none;
}

