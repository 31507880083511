.container {

  overflow: hidden;

  .sidebar {
    background-color:var(--primary-color);
    color:var(--white);
    width: 300px;
    height:100%;
    position: fixed;
    top:0;
    left:0;
    transition: width 0.3s ease;
    z-index: 1000;
    overflow: hidden;

    &__header {
      position: relative;
      padding: 47px 45px;

      &__logo {
        margin: 0 32px 0 0;
        transition: all 3s ease;
      }
    }
    &__menu{

      &__item {
        padding: 16px 45px;

        &__icon {
          margin: 0 22px 0 0;
        }

        &__text {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    &__footer {
      position: absolute;
      padding:0 45px;
      bottom: 40px;
      &__text{
        white-space: nowrap;
      }
    }
  }

  .footer{
    color: var(--white);
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
  }

  .main {
    position: absolute;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    transition: all 0.3s ease;
    background-color: var(--grey-light-3);
    height: 100%;
  }

  .content{
    width: 100%;

    &__container{
      width: 100%;
      padding: 0 66px;
    }
  }
}


.sidebar-collapsed{

  .sidebar {
    width: 90px;
    padding: 0;
    background-color: var(--primary-color-dark);
    transition: background-color 0.4s ease, width 0.3s ease;

    .sidebar__header__logo, .sidebar__menu__item__text{
      display: none;
    }


    &__header {
      padding: 47px 0;
      text-align: center;
    }

    &__header-menu{
      padding:0 20px;
    }

    &__menu {
      text-align: center;

      &__item {
        padding: 17px 13.5px;
        border-radius: 10px;

        &__icon {
          margin: 0;
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 36px;
      padding: 0 17px;
    }
  }

  .main {
    left: 90px;
    width: calc(100% - 90px);
    transition: none;
  }

}


